import React from 'react';
import * as Styled from './homePromosStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import useContentfulAssets from '../../../hooks/useContentfulAssets';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../../utils';

const HomePromos = ({ homePromoData, delay }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Styled.HomePromosItemParagraph>
          {children}
        </Styled.HomePromosItemParagraph>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HomePromosItemHeader>{children}</Styled.HomePromosItemHeader>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Styled.HomePromosItemButton
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
          target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </Styled.HomePromosItemButton>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node.data.target.sys.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <Styled.HomePromosItemImage
            src={asset?.node?.file?.url}
            alt={asset?.node?.title}
          />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };

  return (
    <>
      <Styled.HomePromos>
        <Styled.HomePromosItem
          data-aos="fade-up"
          data-aos-delay={delay}
          data-aos-duration="1000"
          data-aos-once="true"
        >
          {homePromoData?.contentDetails?.raw &&
            documentToReactComponents(
              JSON.parse(homePromoData?.contentDetails?.raw),
              optionsMainStyle
            )}
        </Styled.HomePromosItem>
      </Styled.HomePromos>
    </>
  );
};

export default HomePromos;
